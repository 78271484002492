import React, { useState, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import Header from "../components/header/header";
import InfoCard from "../components/infoCard/infoCard.js";
import ReadMore from "../components/readMore/readMore.js";
import UsersIcon from "../assets/img/svg/users-icon.svg";
import WiFiIcon from "../assets/img/svg/wifi-icon.svg";
import BoltIcon from "../assets/img/svg/bolt-icon.svg";
import WiFI from "../assets/img/home/wifi.png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import CategoryButtons from "../components/categoryButtons/categoryButtons";

const INFOCARDS = [
  {
    title: "Strumento di marketing",
    body:
      "Potrai raccogliere i dati profilati degli utenti e rivolgerti ad un bacino d’utenza in costante aumento",
    filename: "infoCard/spiral.png",
    alt: "Spiral",
    to: "/strumento-di-marketing",
  },
  {
    title: "Tracciabilità dei dati",
    body: "È compliant al nuovo GDPR Regolamento (UE) 2016/679",
    filename: "infoCard/track.png",
    alt: "Track",
    to: "/tracciabilità-dei-dati",
  },
  {
    title: "Personalizzazione",
    body:
      "È un software completamente personalizzabile, sia a livello di contenuti che grafico",
    filename: "infoCard/custom.png",
    alt: "Custom",
    to: "/personalizzazione",
  },
  {
    title: "Modalità di autenticazione",
    body: "Per ogni modalità di autenticazione è previsto un diverso redirect",
    filename: "infoCard/key.png",
    alt: "Key",
    to: "/modalità-di-autenticazione",
  },
  {
    title: "Plug&Play",
    body:
      "Il gateway in configurazione standard è Plug&Play e di facile installazione",
    filename: "infoCard/cable.png",
    alt: "Cable",
    to: "/plug-and-play",
  },
];

const IndexPage = () => {
  const theme = useContext(ThemeContext);

  const [visible, setVisible] = useState(false);

  function onEnterViewport() {
    setVisible(true);
  }

  return (
    <>
      <Header
        title="Connect@You - Sistemi di Reti Wifi ed HotSpot"
        metaDescription="Un moderno servizio di hotspot che permette di offrire ai tuoi clienti una connessione WiFi gratuita, facile e sicura"
        metaKeywords="wifi hotel"
      />
      <div className="columns mt-6">
        <div className="column is-half-widescreen is-offset-one-quarter-widescreen">
          <p>
            Connect@You è un moderno servizio di hotspot che permette di offrire
            ai tuoi clienti una connessione WiFi gratuita, facile e sicura
            attraverso i numerosi metodi di autenticazione disponibili,
            garantendogli la possibilità di essere subito in rete in pochi
            click, mettendoti al riparo dalla normativa vigente.
          </p>
        </div>
      </div>
      {INFOCARDS.map((i, index) => (
        <InfoCard {...i} index={index} key={index} />
      ))}
      <div className="columns">
        <div className="column is-half is-offset-one-quarter ">
          <h3>Vogliamo stupirti con i nostri numeri</h3>
        </div>
      </div>
      <section>
        <div className="columns mt-6">
          <div className="column ">
            <article>
              <div className="section" />
              <Controller>
                <Scene offset={10} duration="100%" triggerHook="onEnter">
                  <Timeline wrapper={<div className="parallax" />}>
                    <Tween
                      position="0"
                      from={{
                        top: "0%",
                      }}
                      to={{
                        top: theme.isMobile ? "-10%" : "-25%",
                      }}
                    >
                      <div className="tween">
                        <img src={WiFI} alt="w" />
                      </div>
                    </Tween>
                  </Timeline>
                </Scene>
                <div className="section" />
              </Controller>
              <div className="section" />
              <ScrollTrigger onEnter={onEnterViewport}>
                {visible && (
                  <>
                    <div className="columns ourNumbers">
                      <div className="column ourNumber">
                        <div className="ourIcon">
                          <img src={UsersIcon} alt="" />
                        </div>
                        <p>Utenti Unici</p>
                        <CountUp
                          start={0}
                          end={1902321}
                          duration={2.75}
                          separator="."
                        />
                      </div>
                      <div className="column ourNumber">
                        <div className="ourIcon">
                          <img src={WiFiIcon} alt="" />
                        </div>
                        <p>Connessioni</p>
                        <CountUp
                          start={0}
                          end={4489129}
                          duration={2.75}
                          separator="."
                        />
                      </div>
                      <div className="column ourNumber">
                        <div className="ourIcon">
                          <img src={BoltIcon} alt="" />
                        </div>
                        <p>Reti attive</p>
                        <CountUp
                          start={0}
                          end={131}
                          duration={2.75}
                          separator="."
                        />
                      </div>
                    </div>
                    <div className="container">
                      <div className="columns">
                        <div className="column overHundred is-three-fifths is-offset-one-fifth">
                          <p>
                            Oltre 100 clienti hanno scelto l’eccellenza di una
                            rete sicura ed affidaile.
                          </p>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column ourCustomers">
                          <ReadMore
                            to="/i-nostri-clienti"
                            text="Scopri i nostri clienti"
                            color="white"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </ScrollTrigger>
            </article>
            <CategoryButtons />
          </div>
        </div>
      </section>
    </>
  );
};

export default IndexPage;
