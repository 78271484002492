import React from "react";
import Image from "../image/image.js";
import ReadMore from "../readMore/readMore.js";
import "./style.scss";

const InfoCard = ({ title, body, filename, alt, to, index }) => (
  <div className="infoCard">
    {index % 2 === 0 ? (
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <div className="infoCardText">
              <h2>{title}</h2>
              <br/>             
              {body}
            </div>
            <br />
            <ReadMore to={to} text="Scopri di più" />
          </div>
          <div className="column is-7">
            <div className="infoCardImageContainer">
              <div className="infoCardImage floatA">
                <Image filename={filename} alt={alt} />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="container">
        <div className="columns invert">
          <div className="column is-7">
            <div className="infoCardImageContainer left">
              <div className="infoCardImage floatB">
                <Image filename={filename} alt={alt} />
              </div>
            </div>
          </div>
          <div className="column  is-5">
            <div className="infoCardText">
              <h2>{title}</h2>
              <br/>
              {body}
            </div>
            <br />
            <ReadMore to={to} text="Scopri di più"/>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default InfoCard;
